import React, { createContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Amplify from 'aws-amplify';
import {
  Card,
  CardTitle,
  CardText,
  CardBody,
  CardFooter,
  Button,
} from 'reactstrap';
import { Network } from '@sonic-web-dev/types';
import { currentNetworkQuery } from '@/graphql/queries';
import { Vars } from '@/utils';
import { BaseMutationOptions, ExecutionResult } from 'react-apollo';
import { StringLiteral } from 'typescript';
// TODO - move hook to context so we can force re-render

interface Fee {
  amount: number;
  type: 'fixed' | 'dynamic' | 'aged' | 'overflow';
  agentFundedPercent: number;
}

interface Broker {
  brokerName: string;
  brokerID: string;
}

interface Manager {
  name: string;
  agentID: string;
}

interface Dashboard {
  code: string;
  name: string;
  filters: string[];
  managers: Manager[];
}

export interface CustomNetwork extends Network {
  allowedStates: string[];
  povertyLevels: any;
  id?: string;
  marketplace: string;
  brokerID: string;
  overflowPriceTierID?: string;
  corpID: string;
  allowDynamicPrice: boolean;
  allowDynamicBidding: boolean;
  allowCategories: boolean;
  fees: Fee[];
  vendorLayerPrices?: {
    rejectDuplicates?: number;
    rejectDisconnected?: number;
    enhanceProfile?: number;
  };
  networkName?: string;
  reportOptions?: {
    label: string;
    reportType: string;
  };
  refundsVisible?: boolean;
  flags: {
    allowAttributes?: boolean;
  };
  leadTypes?: string[];
  brokers?: Broker[];
  duplicateLevels?: string[];
  adminDashboards: Dashboard[];
  groups: any[];
}

interface NetworkUpdateVariables {
  input: Partial<CustomNetwork>;
}

interface NetworkUpdateResponse {
  agentUpdateOne: CustomNetwork;
}

export const NetworkContext = createContext<{
  network?: CustomNetwork;
  loading: boolean;
  updating: boolean;
  changeNetwork: (id: string, changePage?: boolean) => void;
  update: (
    options?: BaseMutationOptions<NetworkUpdateResponse, NetworkUpdateVariables>
  ) => Promise<ExecutionResult>;
}>({
  network: undefined,
  loading: true,
  updating: false,
  changeNetwork: () => {},
  update: () => ({} as any),
});

const NetworkProvider: React.SFC = ({ children }) => {
  const [networkID, setNetworkID] = useState(
    localStorage.getItem('admin-network-id')
  );

  const history = useHistory();
  const { data, loading, error, client } = useQuery(currentNetworkQuery, {
    variables: {
      id: networkID || '',
    },
  });

  useEffect(() => {
    if (networkID) {
      window.localStorage.setItem('admin-network-id', networkID);
    }
  }, [networkID]);

  useEffect(() => {
    if (data) {
      localStorage.setItem('admin-login-success', 'true');
    } else if (error) {
      localStorage.setItem('admin-login-success', 'false');
    }
  }, [!!data, !!error]);

  if (error && error.networkError?.result?.statusCode === 401) {
    return (
      <Card className="w-100" style={{ height: 'fit-content' }}>
        <CardBody>
          <CardTitle>Unauthorized</CardTitle>
          <CardText>
            You are not able to access this screen. If you need to, please reach
            out to a manager or admin to gain access.
          </CardText>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            onClick={() => {
              Amplify.Auth.signOut();
              client.cache.reset();
            }}
          >
            Return to Sign In Page
          </Button>
          <Button
            color="primary"
            className="ml-4"
            onClick={() => {
              const [, host] = window.location.host.split('.');
              window.open(
                `https://${Vars.env !== 'prod' ? 'dev' : 'app'}.${host}.com`
              );
            }}
          >
            Marketplace
          </Button>
        </CardFooter>
      </Card>
    );
  }

  // If this is the user's first time loading the app we dont want to show the nav bar before
  // we know if they have access or not
  if (loading && localStorage.getItem('admin-login-success') !== 'true') {
    return null;
  }

  return (
    <NetworkContext.Provider
      value={{
        network: data?.currentNetwork,
        loading,
        error,
        changeNetwork: (id: string, changePage?: boolean) => {
          setNetworkID(id);
          if (changePage !== false) {
            history.push('/');
          }
        },
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export default NetworkProvider;

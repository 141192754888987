/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Row,
  Col,
  InputGroup,
} from 'reactstrap';
import qs from 'querystring';
import { Storage } from 'aws-amplify';
import Pagination, {
  PaginationContent,
  usePagination,
} from '@availity/pagination';
import {
  FaSort,
  FaSortDown,
  FaSortUp,
  FaLock,
  FaPhone,
  FaCircle,
  FaUserFriends,
  FaCalendarMinus,
} from 'react-icons/fa';

import { Formik } from 'formik';
import { Input } from '@availity/form';
import { SelectField } from '@availity/select';
import { DateRangeField } from '@availity/date';
import moment from 'moment';
import * as yup from 'yup';
import { dateRange } from '@availity/yup';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation } from 'react-router-dom';

import {
  // leadPaginationQuery,
  leadAggregationQuery,
  reportManyQuery,
  getLeadsQuery,
  getSearchOptionsQuery,
} from '@/graphql/queries';
import { LeadsShowExportModal, TableOptionsModal } from '@/modals';
import { availableColumns } from '@/utils/leadTableHelpers';
import { Vars, toUTCDate } from '@/utils';
import access_obj from '@/utils/uiAccess';
import { SearchControls, SearchSummary } from '@/components';
import { useNetwork, useAdmin, useNotifications } from '@/hooks';

const stateAbbreviations = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

console.log(availableColumns);

interface ColumnConfig {
  dataField: string;
  displayName: string;
  formatter?: (value: any) => any;
}

interface TableRowProps {
  [key: string]: any;
  columns: ColumnConfig[];
  sortKey: string;
  descending: boolean;
}

const getIcon = (icon: string, color: string) => {
  switch (icon) {
    case 'phone':
      return <FaPhone color={color} />;
    case 'lock':
      return <FaLock color={color} />;
    case 'people':
      return <FaUserFriends color={color} />;
    default:
      return <FaCircle color={color} />;
  }
};
const getColor = (type: string): string => {
  switch (type) {
    case 'reject':
      return 'red';
    case 'block':
      return 'yellow';
    case 'pass':
      return 'green';
    default:
      return 'green';
  }
};

const convertToLocalWithOffset = (utcTimestamp: string): string => {
  const date = new Date(utcTimestamp);

  // Get the local timezone offset in minutes
  const offsetMinutes = date.getTimezoneOffset();

  // Calculate the total offset in hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMins = Math.abs(offsetMinutes) % 60;

  // Determine if the offset is positive or negative
  const offsetSign = offsetMinutes > 0 ? '-' : '+';

  // Format the offset string
  const offsetString = `${offsetSign}${String(offsetHours).padStart(
    2,
    '0'
  )}:${String(offsetMins).padStart(2, '0')}`;

  // Set the time to the start of the day (00:00:00) in UTC
  date.setUTCHours(0, 0, 0, 0);

  // Apply the offset to the date
  date.setMinutes(date.getMinutes() - offsetMinutes);

  // Adjust the time to reflect the start of the day in local time
  date.setHours(date.getHours() + offsetHours * (offsetSign === '+' ? 1 : -1));

  // Format the local time with offset
  const localTimeWithOffset = `${date.getFullYear()}-${String(
    date.getMonth() + 1
  ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(
    date.getHours()
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds()
  ).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(
    3,
    '0'
  )}${offsetString}`;

  return localTimeWithOffset;
};

const initialColumns = localStorage.getItem('leads-table-columns')?.split(',');

const TableRow: React.SFC<TableRowProps> = ({
  columns,
  filters,
  sortKey,
  descending,
  ...rest
}) => (
  <tr>
    {columns.map((column, key) => {
      const { dataField, formatter } = column;
      if (dataField === 'result') {
        const { icon = '', result = '' } = rest[dataField] || {};
        return (
          <td key={`${key}-${dataField}`}>
            {getIcon(icon, getColor(rest.status))}
          </td>
        );
      }

      if (dataField === 'date') {
        const value = rest[filters?.dateType];
        return (
          <td key={`${key}-date`}>
            {formatter !== undefined ? formatter(value) : value}
          </td>
        );
      }

      return (
        <td key={`${key}-${dataField}`}>
          {formatter !== undefined
            ? formatter(rest[dataField])
            : rest[dataField]}
        </td>
      );
    })}
  </tr>
);

const NoItemsMessage: React.SFC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  ...rest
}) => {
  const { page, loading } = usePagination();

  if (loading && (!page || page.length === 0)) {
    return <span {...rest}>Loading Items...</span>;
  }

  return <span {...rest}>{!page || (!page.length && children)}</span>;
};

const isWithin120Days = (startDate, endDate) => {
  if (!startDate || !endDate) return false; // If either date is missing, return false

  const start = moment(startDate);
  const end = moment(endDate);

  // Calculate the difference in days
  const differenceInDays = end.diff(start, 'days');

  // Check if the difference is 120 days or less
  return differenceInDays <= 120;
};

const Leads: React.SFC = () => {
  const history = useHistory();
  const location = useLocation();
  const initialSearch = useMemo(() => qs.parse(location.search.slice(1)), [
    location.search,
  ]);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showTableOptionsModal, setTableOptionsModal] = useState(false);
  const [queryUsed, setQueryUsed] = useState('');
  const [qryParams, setQueryParams] = useState('');

  const { create } = useNotifications();
  const { role } = useAdmin();
  const { network } = useNetwork();
  const { data = {}, loading } = useQuery(reportManyQuery, {
    skip: !network?.networkID,
    variables: {
      folderID: network?.networkID,
      reportFolder: 'network',
    },
  });

  // const { data: searchOptions = {}, loading: searchLoading } = useQuery(getSearchOptionsQuery, {
  //   skip: !network?.networkID,
  //   variables: {
  //     networkID: network?.networkID,
  //   },
  // });

  useEffect(() => {
    Storage.configure({
      AWSS3: {
        bucket: `reports.leadarena-${Vars.env}`,
        region: 'us-east-1',
      },
    });
  }, []);

  const exportItems = async ({ columns, values }: any): Promise<void> => {
    setShowExportModal(true);

    const filePath = `leads/exports/searchPayload-${moment().format()}.json`;

    Storage.vault.put(
      filePath,
      JSON.stringify({
        type: 'admin-lead',
        filters: values,
        q: queryUsed,
        qryParams,
        folderID: network?.networkID,
        reportFolder: 'network',
        // fields: columns.filter((item: string) => item !== 'result'),
      }),
      {
        level: 'private',
        contentType: 'application/json',
        bucket: `report-filters-${Vars.env}`,
        region: 'us-east-1',
      }
    );
  };

  const { reportMany = [] } = data;
  const noGeneratingReports = reportMany.every(
    ({ status }: any) => status !== 'GENERATING'
  );
  // const { vendors = [], layers = [], categories = [] } = searchOptions;

  // if (searchLoading) return null;
  if (loading) return null;

  return (
    <Formik<{
      size: number;
      searchText: string;
      sort: any;
      dateType: string;
      dateRange: any;
      vendorID?: any;
      layerID?: any;
      mpVendorCampaignID?: any;
      columns: string[];
      leadType?: string;
      region?: string;
      leadStatus: string;
    }>
      initialValues={{
        size: localStorage.getItem('leads-table-page-size')
          ? parseInt(localStorage.getItem('leads-table-page-size')!)
          : 25,
        leadStatus: initialSearch.ls || 'all',
        searchText: initialSearch.q || '',
        sort: { created: 'DESC' },
        dateType: initialSearch.dt || 'created',
        dateRange:
          initialSearch.fr && initialSearch.to
            ? {
                startDate: moment(initialSearch.fr).format('YYYY-MM-DD'),
                endDate: moment(initialSearch.to).format('YYYY-MM-DD'),
              }
            : {
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
              },
        columns: availableColumns
          .filter(({ dataField }) =>
            access_obj[role].lead.allowedColumns.includes(dataField)
          )
          .filter(
            (aC) =>
              !initialColumns || initialColumns.find((c) => c === aC.dataField)
          )
          .map((aC) => aC.dataField),
        vendorID: initialSearch.v,
        layerID: initialSearch.vl,
        region: initialSearch.r,
        mpVendorCampaignID: initialSearch.vc,
        leadType: initialSearch.lt || 'vendor',
        leadStatus: initialSearch.ls || 'all',
      }}
      validationSchema={yup.object().shape({
        size: yup.number(),
        searchText: yup.string(),
        dateRange: dateRange({}),
        sort: yup.object(),
        dateType: yup.string(),
        vendorID: yup.string().nullable(),
        layerID: yup.string().nullable(),
        mpVendorCampaignID: yup.string().nullable(),
        leadStatus: yup.string().nullable(),
        leadType: yup.string().nullable(),
      })}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ values, setFieldValue, errors, submitForm }) => {
        const client = useApolloClient();
        const searchDate = {
          gte: values.dateRange.startDate,
          lte: values.dateRange.endDate,
        };

        const filter = {
          q: values.searchText,
          vendorID: values.vendorID || undefined,
          layerID: values.layerID || undefined,
          mpVendorCampaignID: values.mpVendorCampaignID || undefined,
          leadType: values.leadType || 'vendor',
          networkID: network?.networkID,
          region: values.region || undefined,
          leadStatus: values.leadStatus,
          dateType: values.dateType || 'created',
          searchDate:
            searchDate.gte !== undefined && searchDate.lte !== undefined
              ? searchDate
              : undefined,
        };

        const { data: searchOptions = {}, loading: searchLoading } = useQuery(
          getSearchOptionsQuery,
          {
            skip: !network?.networkID,
            variables: {
              networkID: network?.networkID,
            },
          }
        );

        useEffect(() => {
          localStorage.setItem(
            'leads-table-columns',
            values.columns.toString()
          );
        }, [values.columns]);

        useEffect(() => {
          if (!initialSearch.fr) {
            history.push(
              `/leads?${qs.stringify({
                q: values.searchText,
                v: values.vendorID,
                vc: values.mpVendorCampaignID,
                dt: values.dateType,
                lt: values.leadType,
                fr:
                  typeof values.dateRange.startDate === 'string'
                    ? values.dateRange.startDate
                    : values.dateRange.startDate?.format('YYYY-MM-DD'),
                to:
                  typeof values.dateRange.endDate === 'string'
                    ? values.dateRange.endDate
                    : values.dateRange.endDate?.format('YYYY-MM-DD'),
                r: values.region,
                ls: values.leadStatus,
              })}`
            );
          }
        }, [
          values.leadType,
          values.searchText,
          values.vendorID,
          values.layerID,
          values.mpVendorCampaignID,
          values.dateRange,
          values.dateType,
          values.region,
          values.leadStatus,
        ]);

        useEffect(() => {
          localStorage.setItem('leads-table-page-size', values.size.toString());
        }, [values.size]);

        const fetchItems = async (
          currentPage: number,
          itemsPerPage: number
        ): Promise<any> => {
          console.log(filter);

          const {
            leadType = 'vendor',
            searchDate,
            vendorID,
            layerID,
            mpVendorCampaignID,
            region,
            leadStatus,
            networkID,
            dateType = 'created',
          } = filter;

          const { gte: start, lte: end } = searchDate as any;

          console.log(`searchDate`);
          console.log(searchDate);

          const startDate = toUTCDate(start, true);
          const endDate = toUTCDate(end, false);

          const { sort } = values;
          console.log(sort);
          try {
            const response = await client.query<any>({
              query: getLeadsQuery,
              variables: {
                limit: itemsPerPage,
                offset: (currentPage - 1) * itemsPerPage,
                leadType,
                start: startDate,
                end: endDate,
                vendorID,
                layerID,
                mpVendorCampaignID,
                region,
                leadStatus,
                networkID,
                sort,
                dateType,
              },
              fetchPolicy: 'network-only',
            });
            console.log(response);
            const currentPageData = response.data?.getLeads.leads;
            setQueryUsed(response.data?.getLeads.qry);
            setQueryParams(response.data?.getLeads.qryParams);

            return {
              totalCount: response.data.getLeads.totalCount,
              items: currentPageData,
            };
          } catch (error) {
            const errorMessage = error as any;
            create('danger', errorMessage.message);
            return {
              totalCount: 0,
              items: [],
            };
          }
        };

        const getSortIcon = (dataField: string): React.ReactNode => {
          if (dataField === 'date') {
            dataField = values.dateType;
          }

          const { [dataField]: sort } = values.sort;

          if (sort === 'DESC') {
            return <FaSortDown />;
          }

          if (sort === 'ASC') {
            return <FaSortUp />;
          }

          return <FaSort />;
        };

        const updateSort = (dataField: string): void => {
          if (dataField === 'date') {
            dataField = values.dateType;
          }

          const { [dataField]: sort, ...restSort } = values.sort;

          if (!sort) {
            // setFieldValue('sort', {
            //   ...values.sort,
            //   [dataField]: 'DESC',
            // });
            setFieldValue('sort', {
              [dataField]: 'DESC',
            });
          } else if (sort === 'DESC') {
            // setFieldValue('sort', {
            //   ...values.sort,
            //   [dataField]: 'ASC',
            // });
            setFieldValue('sort', {
              [dataField]: 'ASC',
            });
          } else {
            setFieldValue('sort', restSort);
          }

          console.log(values.sort);

          submitForm(); // Trigger the form submission when sort is updated
        };

        const route_map = {
          export: (
            <DropdownItem
              onClick={() => {
                if (errors && errors.dateRange) {
                  create('danger', 'Please fix search range before exporting');
                } else if (!noGeneratingReports) {
                  create(
                    'danger',
                    'You can only generate one report at a time.',
                    3000
                  );
                } else {
                  exportItems({ ...values, queryUsed });
                }
              }}
              key="export-items"
            >
              Export
            </DropdownItem>
          ),
        } as any;

        const actions: any = useMemo(
          () =>
            access_obj[role].lead.actions.map(
              (action: any) => route_map[action]
            ),
          [route_map]
        );

        const displayColumns = useMemo(
          () =>
            availableColumns.filter((aC) =>
              values.columns.find((c) => c === aC.dataField)
            ),
          [values.columns]
        );

        if (searchLoading) return null;

        const {
          vendors = [],
          layers = [],
          categories = [],
        } = searchOptions.getSearchOptions;

        console.log(searchOptions.getSearchOptions);

        return (
          <>
            <Pagination
              items={fetchItems}
              itemsPerPage={values.size}
              watchList={[values.sort]}
              resetParams={[
                values.size,
                values.mpVendorCampaignID,
                values.vendorID,
                values.layerID,
                values.region,
                values.leadType,
                values.searchText,
                values.dateRange,
                values.dateType,
                values.leadStatus,
              ]}
            >
              <Card className="my-5 mx-3">
                <CardHeader>
                  <CardTitle
                    tag="h3"
                    className="d-flex justify-content-between align-items-center mb-0"
                  >
                    Leads
                    <div>
                      {access_obj[role].lead.actions.length > 0 && (
                        <UncontrolledButtonDropdown>
                          <DropdownToggle
                            id="actions-dropdown"
                            color="dark"
                            caret
                          >
                            Actions
                          </DropdownToggle>
                          <DropdownMenu right>{actions}</DropdownMenu>
                        </UncontrolledButtonDropdown>
                      )}
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <SearchControls
                    openTableOptionsModal={() =>
                      setTableOptionsModal((o) => !o)
                    }
                    advancedSearch={
                      <Row style={{ maxWidth: 530 }}>
                        <Col xs={12}>
                          <DateRangeField
                            className="leads-date-range"
                            name="dateRange"
                            id="dateRange"
                            label="Date Range (120 Days at a time)"
                            customArrowIcon="-"
                            calendarIcon={
                              <div>
                                <FaCalendarMinus />
                              </div>
                            }
                            datepickerProps={{
                              renderMonthElement: undefined,
                            }}
                            ranges
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="dateType"
                            id="dateType"
                            label="Date Type"
                            options={[
                              { label: 'Created Date', value: 'created' },
                              { label: 'Sold Date', value: 'soldDate' },
                            ]}
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="region"
                            label="Region"
                            isClearable
                            options={stateAbbreviations}
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="vendorID"
                            label="Vendor"
                            isClearable
                            labelKey="vendorName"
                            valueKey="vendorID"
                            options={vendors}
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="layerID"
                            label="Lead Source"
                            isClearable
                            labelKey="description"
                            valueKey="layerID"
                            options={layers.filter((l) => {
                              return l.vendorID === values.vendorID;
                            })}
                          />
                        </Col>
                        <Col xs={6}>
                          <SelectField
                            name="mpVendorCampaignID"
                            label="Category"
                            isClearable
                            labelKey="mpVendorCampaignName"
                            valueKey="mpVendorCampaignID"
                            options={categories.filter((c) => {
                              return c.layerID === values.layerID;
                            })}
                          />
                        </Col>
                        <Col xs={6}>
                          <div className="d-flex align-items-center">
                            <InputGroup className="d-flex justify-content-end w-auto">
                              <SelectField
                                label="Lead Type"
                                name="leadType"
                                className="left-filter-select"
                                placeholder="Lead Type..."
                                options={[
                                  { label: 'Vendor', value: 'vendor' },
                                  { label: 'Recycled', value: 'recycled' },
                                  { label: 'Split', value: 'split' },
                                  {
                                    label: 'Live Transfer',
                                    value: 'liveTransfer',
                                  },
                                ]}
                                isClearable
                              />
                            </InputGroup>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="d-flex align-items-center">
                            <InputGroup className="d-flex justify-content-end w-auto">
                              <SelectField
                                label="Status"
                                className="left-filter-select"
                                placeholder=""
                                options={[
                                  { label: 'All', value: 'all' },
                                  { label: 'Sold', value: 'sold' },
                                  { label: 'Unsold', value: 'unsold' },
                                ]}
                                name="leadStatus"
                                isClearable
                              />
                            </InputGroup>
                          </div>
                        </Col>
                      </Row>
                    }
                  />
                  <SearchSummary className="d-flex justify-content-between py-3" />
                  <Table striped size="sm" hover>
                    <thead>
                      <tr>
                        {displayColumns.map((column: any) => (
                          <th key={column.displayName}>
                            <div
                              className="d-flex align-items-center cursor-pointer"
                              role="button"
                              tabIndex={0}
                              onClick={() => updateSort(column.dataField)}
                              onKeyDown={(e: React.KeyboardEvent) =>
                                e.charCode === 13 &&
                                updateSort(column.dataField)
                              }
                            >
                              {column.displayName}{' '}
                              {getSortIcon(column.dataField)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <PaginationContent
                      itemKey="leadID"
                      component={TableRow}
                      containerTag="tbody"
                      filters={values}
                      columns={displayColumns}
                    />
                  </Table>
                  <NoItemsMessage className="d-flex justify-content-center align-items-center pt-2 pb-2">
                    There are no items left to view
                  </NoItemsMessage>
                </CardBody>
              </Card>
            </Pagination>
            <LeadsShowExportModal
              isOpen={showExportModal}
              toggle={() => setShowExportModal((o) => !o)}
            />
            <TableOptionsModal
              availableColumns={availableColumns}
              isOpen={showTableOptionsModal}
              toggle={() => setTableOptionsModal((o) => !o)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Leads;

import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  CardText,
  Table,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownItem,
  DropdownMenu,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Row,
  Col,
  InputGroup,
} from 'reactstrap';
import { Formik } from 'formik';
import { FaCalendarMinus } from 'react-icons/fa';
import { Input } from '@availity/form';
import { useQuery } from '@apollo/react-hooks';

import { DateRangeField } from '@availity/date';
import { SelectField } from '@availity/select';
import { leadAggregationQuery } from '@/graphql/queries';
import omit from 'lodash.omit';
import moment from 'moment';
import * as yup from 'yup';
import { dateRange } from '@availity/yup';

const leadTypeOptions = [
  {
    label: 'Marketplace',
    value: 'vendor',
  },
  {
    label: 'Agent Direct',
    value: 'agentDirect',
  },
  // live tansfer and recyeld
];

const AnalyticsSearchBar: React.FC<any> = ({
  network,
  handleSubmit,
  changeDashboard,
}) => {
  const [showTableOptionsModal, setTableOptionsModal] = useState(false);
  const { adminDashboards: dashboards = [] } = network || {};

  const created: any = {};
  const dateType = 'created';
  const values: any = {};

  const initialSearch: any = {
    // dt: '',
    // fr: '',
    // to: '',
    // v: '',
    // r: '',
    // vc: '',
  };

  const filter = {
    vendorID: values.vendorID || undefined,
    mpVendorCampaignName: values.mpVendorCampaignName || undefined,
    networkID: network?.networkID,
    [values.dateType]:
      created.gte !== undefined && created.lte !== undefined
        ? created
        : undefined,
  };

  const { data: leadAggData } = useQuery(leadAggregationQuery, {
    variables: {
      fields: ['mpVendorCampaignName', 'vendorID', 'layerID'],
      filter: omit(filter, ['mpVendorCampaignName', 'vendorID', 'layerID']),
    },
  });

  if (!dashboards) {
    return null;
  }

  return (
    <Formik<{
      //dateType: string;
      dashboardCode: string;
      dateRange: any;
      vendorID?: any;
      layerID?: any;
      mpVendorCampaignName?: any;
      managerID?: any;
      //region?: string;
    }>
      initialValues={{
        // dateType: initialSearch.dt || 'soldDate',
        dashboardCode: dashboards.length > 0 ? dashboards[0].code || '' : '',
        dateRange:
          initialSearch.fr && initialSearch.to
            ? {
                startDate: moment(initialSearch.fr, ['YYYY-MM-DD']),
                endDate: moment(initialSearch.to, ['YYYY-MM-DD']),
              }
            : {
                startDate: moment({ hour: 0 }),
                endDate: moment({ hour: 0 }),
              },
        vendorID: initialSearch.v,
        layerID: initialSearch.l,
        mpVendorCampaignName: initialSearch.vc,
        managerID: initialSearch.m,
      }}
      // validationSchema={yup.object().shape({
      //   dateRange: dateRange({}).nullable(),
      //   region: yup.string().nullable(),
      //   vendorID: yup.string().nullable().notRequired(), // campaignID
      //   mpVendorCampaignName: yup.string().nullable().notRequired(), // vendorID || none for transfers
      // })}
      onSubmit={(values: any) => {
        const { vendorID, mpVendorCampaignName, managerID, layerID } = values;
        const selectedDashboard = dashboards.find((d) => {
          return d.code === values.dashboardCode;
        });

        values = selectedDashboard.filters.reduce((t: any, f: string) => {
          if (!values[f]) {
            t[f] = undefined;
          }
          return t;
        }, values);

        handleSubmit(values);
      }}
    >
      {({ values, setFieldValue, errors, submitForm }) => {
        const selectedDashboard = dashboards.find((d) => {
          return d.code === values.dashboardCode;
        });

        const leadSourceData = leadAggData?.leadAggregation
          .find((f) => f.field === 'mpVendorCampaignName')
          .counts.filter((ls) => {
            if (values.vendorID) {
              return ls.key.split('_').join(' ').includes(values.vendorID);
            }
            return true;
          });

        useEffect(() => {
          changeDashboard(values.dashboardCode);
        }, [values.dashboardCode]);

        return (
          <Card className="my-0 mx-0">
            <CardBody>
              <Row>
                <Col xs={3}>
                  <SelectField
                    name="dashboardCode"
                    label="Dashboard"
                    // isClearable
                    labelKey="name"
                    valueKey="code"
                    options={dashboards}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={4}>
                  <DateRangeField
                    className="leads-date-range"
                    name="dateRange"
                    id="dateRange"
                    label="Date Range"
                    customArrowIcon="-"
                    calendarIcon={
                      <div>
                        <FaCalendarMinus />
                      </div>
                    }
                    datepickerProps={{
                      renderMonthElement: undefined,
                    }}
                    ranges
                  />
                </Col>
                {/* <Col xs={6}>
            <SelectField
              name="dateType"
              label="Date Type"
              options={[
                { label: 'Created Date', value: 'created' },
                { label: 'Sold Date', value: 'soldDate' },
              ]}
            />
          </Col> */}

                {selectedDashboard.filters.includes('vendorID') ? (
                  <Col xs={2}>
                    <SelectField
                      name="vendorID"
                      label="Vendor"
                      isClearable
                      labelKey="key"
                      valueKey="key"
                      options={
                        leadAggData?.leadAggregation.find(
                          (f) => f.field === 'vendorID'
                        ).counts || []
                      }
                    />{' '}
                  </Col>
                ) : null}

                {/* {selectedDashboard.filters.includes('layerID') ? (
                  <Col xs={2}>
                    <SelectField
                      name="layerID"
                      label="Lead Source"
                      isClearable
                      labelKey="key"
                      valueKey="key"
                      options={
                        leadAggData?.leadAggregation.find(
                          (f) => f.field === 'layerID.keyword'
                        ).counts || []
                      }
                    />{' '}
                  </Col>
                ) : null} */}

                {selectedDashboard.filters.includes('mpVendorCampaignName') ? (
                  <Col xs={2}>
                    <SelectField
                      name="mpVendorCampaignName"
                      label="Vendor Campaign Name"
                      isClearable
                      labelKey="key"
                      valueKey="key"
                      getOptionLabel={({ key }: any) =>
                        key
                          .split('_')
                          .splice(
                            key.startsWith('next') ||
                              (key.startsWith('quote') &&
                                !key.startsWith('quotewizard'))
                              ? 2
                              : 1
                          )
                          .join(' ')
                      }
                      options={
                        leadSourceData || []
                        // leadAggData?.leadAggregation.find(
                        //   (f) => f.field === 'mpVendorCampaignName.keyword'
                        // ).counts || []
                      }
                    />
                  </Col>
                ) : null}

                {selectedDashboard.filters.includes('managerID') ? (
                  <Col xs={2}>
                    <SelectField
                      name="managerID"
                      label="Manager"
                      isClearable
                      labelKey="name"
                      valueKey="agentID"
                      options={selectedDashboard.managers}
                    />
                  </Col>
                ) : null}

                <Col
                  xs={1}
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    onClick={submitForm}
                    style={{ height: '60%' }}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        );
      }}
    </Formik>
  );
};

export default AnalyticsSearchBar;
